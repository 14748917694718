<template>
  <div>
    <b-card title="Penting!" class="mb-2">
      <b-card-text>
        <ul>
          <li>
            Input suara di TPS wilayah tugas Anda sesuai dengan data yang
            tertera pada Formulir Model C1.
          </li>
          <li>
            Ketelitian dan kehati-hatian petugas dalam melakukan penginputan
            data sangat dibutuhkan karena data yang telah dikirim tidak dapat
            diubah kembali.
          </li>
          <li>
            Periksa kembali hasil inputan Anda sebelum mengirimkannya ke pusat
            data.
          </li>
          <li>Foto Formulir Model C1 sebagai bahan validasi.</li>
        </ul>
      </b-card-text>
      <b-button :disabled="isDisabled" class="float-right" variant="primary" @click.prevent="openAlert"
        >Saya Paham</b-button
      >
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mounted: false,
      isDisabled: true,
      level: parseInt(this.$route.params.level),
      levelName: "",
      regionId: "",
    };
  },
  mounted() {
    this.mounted = true;
    this.whenMounted();
  },
  watch: {},
  methods: {
    async whenMounted() {
      if (this.level === 1) {
        this.levelName = "DPR";
        this.regionId = process.env.VUE_APP_DPR_ID;
      } else if (this.level === 2) {
        this.levelName = "DPRD TK I";
        this.regionId = process.env.VUE_APP_DPRD_ID;
      } else {
        this.levelName = "DPRD TK II";
        this.regionId = process.env.VUE_APP_DPRD_II_ID;
      }

      try {
        const resp = await this.$http.get(
          process.env.VUE_APP_API_URL +
            "input-suara/" +
            (this.$store.getters["account/accountData"].role === "verifikator"
              ? "verifikator"
              : "petugas") +
            "/worksheet/" +
            this.regionId
        );

        if (this.$store.getters["account/accountData"].role === "verifikator") {
          if (!resp.data) {
            this.isDisabled = true;
            const alert = await this.$swal
              .fire({
                title: "Petugas Belum Selesai Input Suara",
                text: `Tampaknya petugas belum menginput suara ${this.levelName}, silahkan hubungi admin jika anda merasa ini kesalahan.`,
                icon: "warning",
                showCancelButton: false,
                confirmButtonText: "Mengerti",
                cancelButtonText: "Tidak",
              })
              .then((result) => {
                if (result.value) {
                  // do here
                }
              });

            alert;
          } else if (resp.data.data.status !== "pending") {
            this.isDisabled = true;
            const alert = await this.$swal
              .fire({
                title: "Telah Selesai Input Suara",
                text: `Anda sudah menginput suara dan saat ini status data anda ${resp.data.data.status}, silahkan hubungi admin jika anda merasa ini kesalahan.`,
                icon: "warning",
                showCancelButton: false,
                confirmButtonText: "Mengerti",
                cancelButtonText: "Tidak",
              })
              .then((result) => {
                if (result.value) {
                  // do here
                }
              });

            alert;
          } else {
            this.isDisabled = false;
          }
        } else {
          if (resp.data) {
            this.isDisabled = true;
            const alert = await this.$swal
              .fire({
                title: "Telah Selesai Input Suara",
                text: `Anda sudah menginput suara, silahkan hubungi admin jika anda merasa ini kesalahan.`,
                icon: "warning",
                showCancelButton: false,
                confirmButtonText: "Mengerti",
                cancelButtonText: "Tidak",
              })
              .then((result) => {
                if (result.value) {
                  // do here
                }
              });

            alert;
          } else {
            this.isDisabled = false;
          }
        }
      } catch (error) {
        this.$swal.fire({
          title: "Error",
          text: error,
          icon: "error",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    },
    async openAlert() {
      await this.$swal
        .fire({
          title: "Perhatian!",
          text: "Pastikan anda mengisi data dengan benar dan teliti.",
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "Mulai",
          cancelButtonText: "Tidak",
        })
        .then((result) => {
          if (result.value) {
            this.$router.push(
              `/input-suara/calon/${this.$route.params.level}/form`
            );
          }
        });
    },
  },
};
</script>